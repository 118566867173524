import docReady from '../../../assets/js/docReady.js';
import registerCollapseComponents from '../../../abstracts/collapse/_collapse.js';

/*
    Register  all collapse components for this block
 */
docReady(() => {
    const collapseAndVisualBlocks = Array.from(document.getElementsByClassName('block-faq-block'));

    collapseAndVisualBlocks.forEach((block) => {
        registerCollapseComponents(block);
    });
});
